import { FC, useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';

interface SeoTextProps {
  content: string;
}

const SeoText: FC<SeoTextProps> = ({ content }) => {
  const [isToggleButtonVisible, setIsToggleButtonVisible] = useState(false);
  const [isToggleEnabled, setIsToggleEnabled] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!wrapperRef.current) return;

    const hasOverflow =
      wrapperRef.current.scrollHeight > wrapperRef.current.clientHeight;

    setIsToggleButtonVisible(hasOverflow);
  }, [wrapperRef, content]);

  const toggleContent = () => {
    setIsToggleEnabled(!isToggleEnabled);
  };

  const wrapperClassList = useMemo(() => {
    if (!isToggleEnabled) return 'max-h-[242px] overflow-hidden mb-[50px]';
    return '';
  }, [isToggleEnabled]);

  return (
    <div className="relative">
      <div ref={wrapperRef} className={`seo-text ${wrapperClassList}`}>
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
        {isToggleButtonVisible && (
          <SeoTextToggleButton
            onToggle={toggleContent}
            expanded={isToggleEnabled}
          />
        )}
      </div>
    </div>
  );
};

const SeoTextToggleButton: FC<{ onToggle: () => void; expanded: boolean }> = ({
  onToggle,
  expanded
}) => {
  return (
    <div
      className={cx('absolute left-0 w-full', {
        'bg-gradient-to-t from-white via-white py-[30px] bottom-[-80px]': !expanded,
        'py-0 bottom-[-60px]': expanded
      })}>
      <button
        onClick={onToggle}
        className={cx(
          'block h-[42px] w-full items-center justify-center rounded-[6px] border border-brand-grey400 bg-white px-[36px] text-center font-medium font-medium leading-[38px] text-brand-black100 transition-colors duration-200 hover:text-brand-orange sm:w-auto',
          {
            'mt-[30px]': !expanded,
            'mt-0': expanded
          }
        )}>
        {expanded ? 'Read Less' : 'Read More'}
      </button>
    </div>
  );
};

export default SeoText;
