import { useRouter } from 'next/router';
import SeoText from './SeoText';
import { SeoBlock, SeoFaq } from 'constants/dataTypes';

const CategorySeoText = (
  {
    seoBlock
  }: {
    seoBlock: SeoBlock;
  }
) => {
  const router = useRouter();
  if (!seoBlock) return null;

  let seoContent = seoBlock.text ?? '';

  const faqHtmlContent = seoBlock.faq.reduce((acc:string, curr:SeoFaq) => {
    if(curr) {
      acc +=  '<p class="font-bold">' + curr.question + '</p>' + curr.answer;
    }
    return acc;
  }, '');
  if(faqHtmlContent !== '') {
    seoContent += `<h3 class="seo-title-2">Frequently Asked Questions</h3>`;
  }
  seoContent += faqHtmlContent;

  return <SeoText content={seoContent} key={router.asPath} />;
};

export default CategorySeoText;
